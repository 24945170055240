.products-variants-modal {
  .modal_list {
    display: flex;
    justify-content: space-between;
    gap: 10rem;
    margin-top: 2rem;

    @include mobile {
      flex-direction: column;
      gap: 1rem;
    }
  }

  ul {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
  }

  li {
    font-size: $font-size-normal;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    font-weight: bold;

    span {
      font-weight: normal;

      &.status {
        color: #1890ff;
      }
    }
  }
}

.modal_content {
  @include mobile {
    width: 95% !important;
  }
}

.action_modal {
  .modal_content {
    max-height: 90vh;
    overflow: auto;
  }

  .variable-value {
    word-break: break-all;
  }
}

.action_modal__heading {
  margin-bottom: 3rem;
}
