%text-selection-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
