.page__search_filters {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3rem;

  @include md {
    grid-template-columns: repeat(1, 1fr);
  }

  .error_msg {
    color: $color-delete-btn;
    margin-top: 1rem;
  }
}
