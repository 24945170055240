body {
  background-color: $main-bg;
}

.header {
  padding: 0 4rem;
  height: 6rem;
  background-color: $color-black;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    padding: 0 1rem;
  }

  .logo {
    svg {
      height: 4rem;
      width: 6rem;
    }
  }

  .user {
    color: $color-white;
    font-size: $font-size-small;
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    cursor: pointer;

    &__avatar {
      border-radius: 50%;
      background-color: $color-white;
      color: $color-black;
      width: 3.5rem;
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__dropdown {
      position: absolute;
      top: 6rem;
      right: 0;
      background-color: $color-white;
      color: $color-black;
      padding: 3rem 3rem 1.5rem;
      border: 1px solid #64707d;
      border-radius: 5px;
      z-index: 10;
      cursor: default;
      display: none;

      &.show {
        display: block;
      }

      li {
        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }

      .logout {
        cursor: pointer;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 1rem;

      .user__avatar {
        background-color: $color-black;
        color: $color-white;
      }
    }

    &__email {
      display: flex;
      flex-direction: column;

      .email {
        color: #64707d;
      }
    }
  }
}

.container {
  display: flex;
  padding: 4rem;

  @include desktop {
    padding-bottom: 0;
  }

  @include mobile {
    flex-direction: column;
    padding: 1rem;
    position: relative;
  }
}

.main {
  width: 100%;
  padding: 2rem;

  @include custom-scroll-hidden;

  @include mobile {
    padding: 2rem 0;
  }

  @include desktop {
    height: calc(100vh - 12rem);
    overflow: auto;
  }
}

.page {
  &__buttons {
    margin-bottom: 2rem;
  }

  &__search {
    display: flex;
    gap: 4rem;
    margin-bottom: 2rem;
  }
}

.app-alert-container {
  margin: 0 auto;
  position: fixed;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  top: 8rem;
  display: none;

  .alert-content {
    font-size: 1.3rem !important;
    width: fit-content !important;
    min-height: 50px;

    @include mobile {
      width: 300px !important;
    }

    // .dismiss {
    //   background-color: $color-white;
    // }
  }
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.listing-ml-40 {
  margin-left: 40px;
  margin-top: -10px;
}

.remove-accordian-style {
  width: 0%;
  border: 0px;
  margin-bottom: 0px;
  border-radius: 0px;
}

.custom_checkbox {
  opacity: 0;
}

.hideOverflow {
  overflow: hidden !important;
}

.app-connection-alert {
  margin: 0 auto;
  position: fixed;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  top: 8rem;

  .alert-content {
    font-size: 1.3rem !important;
    width: fit-content !important;
    min-height: 50px;

    @include mobile {
      width: 300px !important;
    }
  }
}