.nav_container {
  position: relative;
  width: 25rem;
  height: calc(100vh - 11rem);
  transition: all 0.3s;
  overflow: auto;

  @include custom-scroll;

  @include mobile {
    display: none;
  }

  &.show {
    left: 0% !important;
  }

  .side_nav {
    @include mobile {
      padding: 2rem;

      a,
      span {
        color: #121213;
      }
    }
  }
}

// Mobile
.mobile_nav {
  width: 100%;
  overflow: auto;
  display: none !important;
  padding: 2rem 0;
  position: relative;

  @include mobile {
    display: block !important;
  }

  div {
    white-space: pre;
  }

  .tabitem-space {
    margin-right: 3.2rem;
  }

  .sub_menu {
    background-color: $main-bg;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    left: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-top: 10rem;

    &__item {
      font-size: $font-size-normal;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

// Mobile Custom
.mobile_nav_custom {
  width: 100%;
  overflow: auto;
  display: none !important;
  padding: 2rem 0;
  position: relative;

  @include mobile {
    display: block !important;
  }

  ul {
    display: flex;
    gap: 3rem;
  }

  li {
    font-size: 14px;
    white-space: pre;

    a {
      color: #737f8f;

      &.active {
        color: #121213;

        &::after {
          content: "";
          display: block;
          height: 2px;
          width: 100%;
          background-color: #0d62ff;
          margin-top: 5px;
        }
      }
    }
  }

  .sub_menu {
    background-color: $main-bg;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: calc(100vh - 14rem);
    top: 14rem;
    left: 0;
    padding: 1rem;
    display: none;
    flex-direction: column;
    gap: 4rem;
    padding-top: 4rem;
    overflow: auto;

    &.show {
      display: flex;
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    a {
      color: #121213;
    }
  }
}
