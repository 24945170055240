.mls_login {
  height: 100vh;
  width: 100vw;
  background-color: $color-black;
  position: fixed;
  top: 0;
  left: 0;

  @include ipad_air {
    padding: 0;
    overflow: auto;
  }

  .wrapper {
    display: flex;
    height: 100%;

    @include ipad_air {
      flex-direction: column;
    }
  }

  .header {
    display: none;
  }

  .banner {
    @extend %flex-center;

    color: $color-white;

    @include ipad_air {
      flex: 1;
    }
  }

  .banner,
  .content {
    flex: 1;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-white;

    @include ipad_air {
      flex: 3;
    }
  }

  .login {
    background-color: $color-white;
    width: 60%;

    @include ipad_air {
      width: 90%;
    }

    .body {
      gap: 4rem;
      margin-top: 2rem;
    }

    .reset_password {
      button {
        color: #0d62ff;
        background: none;
        border: none;
        cursor: pointer;
      }
    }

    .footer {
      justify-content: flex-start;
      margin-top: 4rem;
    }
  }

  .reset {
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;

    .btn__back {
      color: $color-white;
      position: absolute;
      top: 2rem;
      left: 4rem;
      display: flex;
      align-items: center;
      font-size: $font-size-normal;
    }

    @include ipad_air {
      height: auto;
      padding: 6rem 4rem 4rem 4rem;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      gap: 4rem;
      padding: 0 4rem;
      width: 100%;

      @include ipad_air {
        flex-direction: column;
        height: auto;
        padding: 0;
      }
    }
  }

  .block {
    padding: 2rem 4rem;
    background-color: $color-white;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4rem;
    position: relative;

    @include ipad_air {
      width: 100%;
    }

    &:not(:first-child) {
      &::after {
        content: "";
        display: inline-block;
        height: 2px;
        width: 4rem;
        background-color: $color-white;
        position: absolute;
        left: -4rem;
        top: 50%;
        transform: translateY(-50%);

        @include ipad_air {
          height: 4rem;
          width: 2px;
          left: 50%;
          top: -4rem;
          transform: translateX(-50%);
        }
      }
    }

    &.disabled {
      pointer-events: none;

      &::before {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        background-color: rgba($color: #000000, $alpha: 0.4);
        backdrop-filter: blur(0.5px);
      }

      &::after {
        background-color: rgba($color: #fff, $alpha: 0.4);
      }
    }
  }

  .header {
    text-align: center;
    margin-bottom: 2rem;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;

    .timer {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 130%;
      transform: translateX(-50%);
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    gap: 2rem;
    align-items: center;
  }
}
