*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}




#app-loader-container {
  position: fixed;
  background: rgba(231, 231, 231, 0.44);
  display: none;
  z-index: 999999;
  width: 100%;
  height: 100%;
  text-align: center;

}

#app-loader-container > .app-loader-icon {
  width: 8rem;
  //margin-top: 18%;     //imp prev one
  /*-------new improved version-----------*/
  position: fixed;
  top: 40%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  /*---------new verion---------*/
  
}


