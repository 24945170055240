.table_actions {
  display: flex;
  gap: 1rem;
}

.sales_history .page__tabs {
  margin-top: 0;
}

/*--------------muzzammil changes---------------*/

/*.form {
  &__input {
    .required .input__label {
      color: red !important;
    }

    .form-dropdown-required > div > label {
      color: red !important;
    }
  }
}*/

.form__fieldset .change-password-toggle {
  margin-left: 1rem;
}

.sales_history .page__date_picker {
  margin-bottom: 5rem;
}

.sales_history .page__table {
  tfoot {
    display: none;
  }
}

.products-lookup {
  .products-lookup-page-content-margin {
    margin-bottom: 20rem;
  }
  .products-lookup-secondary {
    margin-top: 5rem;
  }
}

.products-discount {
  .form-content-margin {
    margin-bottom: 10rem;
  }
}

.products-discount {
  .page__content {
    .form__input--btn {
      .discount_input {
        display: flex;
        gap: 3rem;
        align-items: end;
        flex: 1;
      }
    }
  }
}

/*--------------muzzammil changes---------------*/

.stock_checkboxes {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;

  @include mobile {
    flex-direction: column;
    align-items: normal;
  }

  span {
    font-size: 14px;
  }
}

.order-products {
  .MsnGR {
    margin-top: 3rem;

    @include mobile {
      width: 100% !important;
    }
  }
}

// .stock-order-products-container {
//   table {
//     @include mobile {
//       min-width: auto !important;
//     }
//   }
// }

// .stock-order-products-container {
//   .table__fixed {
//     @include mobile {
//       min-width: 80rem !important;
//     }
//   }
// }

.btn_row {
  display: flex;
  gap: 1rem;
}

.custom_checkbox {
  span {
    font-size: 13px;
  }
}

.label-stock-count {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.sales_history .page__buttons {
  @include xs {
    flex-direction: column;
  }

  button {
    @include xs {
      width: 100%;
    }
  }
}

// Supplier Table
.suppliers {
  .page__table {
    table {
      @include tablet {
        min-width: 100% !important;
      }
    }
  }
}

.stock_tables .page__table table {
  margin-top: 0;
}

.super_admin .page__table table {
  margin-top: 0;
}

.demo_header {
  overflow: auto;
}
.modal-content-custom {
  font-weight: 400;
  line-height: 1.7;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow: hidden;
}
.reject-button {
  background: #ff4d4f !important;
  border-color: #ff4d4f !important;
  color: #fff !important;
  &:hover {
    background-color: #fff !important;
    color: #ff4d4f !important;
  }
}

.approve-button {
  background: #0dbf42 !important;
  border-color: #0dbf42 !important;
  color: #fff !important;
  &:hover {
    background-color: #fff !important;
    color: #0dbf42 !important;
  }
}

.ml-55 {
  margin-left: 55%;
}

.float-right {
  float: right !important;
}

.custom_search {
  display: flex;
  grid-gap: 4rem;
  gap: 4rem;
  margin-bottom: 2rem;
}

.mt-2R {
  margin-top: 2rem;
}

.barcode_section-ecommerce {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  width: 50% !important;
}