// Mixins
$initial-width: 0px;
$xs-width: 575px;
$sm-width: 767px;
$md-width: 991px;
$lg-width: 1199px;
$xl-width: 1200px;

@mixin xxs {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin ipad_air {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1400px) {
    @content;
  }
}

// Scrollbar
@mixin custom-scroll {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey;
    border: 2px solid $color-grey;
  }
}

@mixin custom-scroll-hidden {
  &::-webkit-scrollbar {
    width: 0;
  }
}

@mixin custom-scroll-horizontol {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey;
    border: 2px solid $color-grey;
  }
}
