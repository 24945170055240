.info_row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
  }

  @include xs {
    grid-template-columns: repeat(1, 1fr);
  }
}
