p {
  font-size: 1.4rem;
}

.heading {
  &--primary {
    font-size: 2.1rem;
  }

  &--secondary {
    font-size: 1.8rem;
  }
}
