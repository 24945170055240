.box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  padding: 2.1rem;
  background-color: $color-white;
  border-radius: 4px;
  flex: 1 1;

  .text {
    font-size: $font-size-normal;
  }

  .number {
    font-size: $font-size-big;
    font-weight: bold;
  }

  .fadeIn {
    animation: fade 1s;
    -webkit-animation: fade 1s;
  }
  @-webkit-keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
