.info_list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;

  @include mobile {
    flex-direction: column;
    width: 100%;
  }

  li {
    font-size: $font-size-normal;
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    flex: 1;
    font-weight: bold;

    span {
      font-weight: normal;
    }
  }
}

// Stock Adjustment
.new_stock_adjustment {
  .page__header {
    @include xs {
      gap: 4rem;
    }
  }
}

.web_orders {
  .form__row {
    justify-content: space-between;
  }

  .table {
    margin-top: 8rem;
  }

  .align-center {
    @include mobile {
      align-items: normal;
    }
  }

  .btn-align-right {
    @include mobile {
      align-self: center;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .button {
    @include mobile {
      align-self: center;
    }
  }
  .barcode-toggle {
    padding-left: 20px !important;
    margin-top: -20px !important;
    display: flex !important;
    justify-content: center !important;

    .label {
      color: #0d62ff;
    }
  }
}
.barcode_section {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  width: 50% !important;
}

.text-margin {
  margin-bottom: 2rem;
  margin-top: 2rem;
}