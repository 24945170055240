.error_404 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $main-bg;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    max-width: 80%;
  }
}
