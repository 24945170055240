.sell {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include mobile {
      flex-direction: column;
      gap: 2rem;
      align-items: initial;
    }

    .left {
      display: flex;
      gap: 2rem;
      align-items: center;
    }

    .page__title,
    .page__buttons {
      margin-bottom: 0;
    }

    .select_lang {
      display: flex;
      align-items: center;
      gap: 2rem;

      .add_customer-btn {
        white-space: nowrap;
      }
    }
  }

  .form__row {
    gap: 1.5rem;

    &:not(:last-child) {
      margin-bottom: 1rem;

      @include mobile {
        margin-bottom: 2rem;
      }
    }

    &.mop {
      margin: 2rem 0;

      .form__input {
        flex-basis: 100% !important;
      }
    }
  }

  .page__table {
    padding-top: 3rem;
    overflow: auto;
    max-height: 40rem;
    @include custom-scroll;

    @include mobile {
      padding-top: 5rem;
      max-height: none;
    }

    // table {
    //   min-width: 60rem;
    // }
  }

  .page__content {
    display: flex;
    gap: 2rem;
    border-radius: 4px;

    @include mobile {
      flex-direction: column;
    }

    .product {
      flex: 2;
      background-color: $color-white;
      padding: 2rem;
      border-radius: 4px;

      .form__input {
        flex-basis: 100% !important;
      }
    }

    .customer {
      flex: 1;
      background-color: $color-white;
      padding: 2rem;
      border-radius: 4px;

      .customer-del-btn {
        cursor: pointer;
      }
      .autocomplete-margin {
        margin-bottom: 7rem;
      }
    }
  }

  .page__buttons {
    justify-content: space-between;
    align-items: baseline;

    .buttons {
      display: flex;
      gap: 1rem;
    }

    @include xs {
      flex-direction: column;
      gap: 2rem;

      .select_lang {
        width: 100%;
      }
    }
  }

  .dead-sale-button {
    background: #ff4d4f !important;
    border-color: #ff4d4f !important;
    color: $color-white;
  }

  .invoice-status-toggle {
    padding-left: 20px !important;
    display: flex !important;
    justify-content: center !important;

    .label {
      color: #0d62ff;
    }
  }
}

.mops-modal-inner-margin {
  margin-bottom: 0.5rem;
}
.mops-modal-left-inner-margin {
  margin-left: 0.5rem;
}
.web-order-modal {
  top: 1rem;
  .modal_content {
    max-height: 500px !important;
    overflow-y: scroll;
  }
}

.align-center {
  align-items: center;
}
.alert-snackbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

.web_orders {
  .form__row {
    justify-content: space-between;
  }

  .table {
    margin-top: 8rem;
  }

  .align-center {
    @include mobile {
      align-items: normal;
    }
  }

  .btn-align-right {
    @include mobile {
      align-self: center;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .button {
    @include mobile {
      align-self: center;
    }
  }
  .barcode-toggle {
    padding-left: 20px !important;
    margin-top: -20px !important;
    display: flex !important;
    justify-content: center !important;

    .label {
      color: #0d62ff;
    }
  }
}

.modal_heading_invoice {
  width: 100%;
}
.green-button {
  background-color: green !important;
  color: white !important;
}

.modal_date_picker {
  min-height: 15rem;

  button {
    @include mobile {
      align-self: flex-end;
    }
  }
}

._gap {
  gap: 0px !important;
}

.add_search_customer {
  display: flex !important;
  gap: 10px !important;
  align-items: center;
  .search_customer {
    float: left;
    width: 65% !important;
  }

  .add_customer-btn {
    float: right;
    width: 50% !important;
    height: 40px !important;
  }
}

.customer_form_popup_btn {
  float: right;
  margin: 5px !important;
  margin-top: 35px !important;
}

.text-margin {
  margin-bottom: 5rem;
  margin-top: 2rem;
}
.barcode_section {
  margin-top: -40px !important;
  margin-bottom: 50px !important;
  width: 50% !important;
}
.discount_dropdown {
  width: 100% !important;
}
.invoice-apply-discount {
  display: flex !important;
  justify-content: center !important;
  margin: 20px;
  .discount-icon {
    float: left;
    width: 8%;
    margin-right: 7px;
  }
  .apply-discount {
    color: #0d62ff;
    align-self: center;
    font-size: 14px;
    font-weight: bold;
    border: 0px !important;
    background: transparent;
  }
}
.invoice-redeem-toggle {
  margin-bottom: 30px;
  gap: 1.5rem;
  display: flex !important;
  justify-content: center !important;

  .enable {
    color: #fff;
    background-color: #0d62ff;
    align-self: flex-end;
  }
  // .enable {
  //   color: wheat;
  //   border: dimgray;
  //   background-color: dimgray;
  //   align-self: flex-end;
  // }
  .points input{
    text-align: center !important;
    padding-bottom: 10px !important;
    margin-top: -10px !important;
    font-weight: bold !important;
  }
}

.promotions {
  overflow: auto;
  &_section {
    --space-y-reverse: 0;
    margin-top: calc(1rem * var(--space-y-reverse));
    margin-bottom: calc(1rem * calc(1 - var(--space-y-reverse)));
    position: relative;
  }
  &_checkboxParent {
    position: absolute !important;
  }
  &_parentContainer {
    position: relative;
  }
  &_noData {
    text-align: center;
  }
  &_heading {
    margin-bottom: 3rem;
  }
  .collapse-card-header {
    min-height: 45px !important;
    .right {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      .card-title {
        font-size: 14px !important;
        font-weight: bold;
        span {
          color: #0d62ff !important;
        }
      }
    }
  }
}
