.add_product {
  .heading--secondary {
    margin-right: 1rem;
  }

  .add_attributes {
    .form__row {
      align-items: end;
      @include mobile {
        flex-direction: row;
      }
    }

    .input_key {
      flex: 2 !important;
    }

    .input_value {
      flex: 1 !important;
    }
  }
}

.products-discount-tag {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #0d62ff;
  color: #fff;
}

.products-zero-discount-tag {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #c4c4c4;
}

.product-switch-heading {
  margin-bottom: 3rem !important;
}

.thumbnail {
  display: block;
  padding: 4px;
  width: 200px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.products-inventory-tracking {
  grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
    column-gap: 2rem;
    grid-row-gap: 2rem;
    row-gap: 2rem;
    width: 50%;

    .floated-label .input__label {
      top: 0px !important;
    }
}

.chipsContainer {
  flex-basis: 50% !important;
}

.downloadbtn {
  float: right;
}


.stock-autocomplete-popup {
  height: 200px;
  overflow: auto;
  font-size: 13px;
  cursor: pointer;

  ul {
    list-style-type: none;
  }
}
.products-search-list-item {
  margin-bottom: 1.5rem;
}
.stock-autocomplete-popup .search {
  padding: 15px !important;
  div ul {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }
}

.stock-autocomplete-popup::-webkit-scrollbar-thumb {
  //background-image: linear-gradient(180deg, #D0368A 0%, #708AD4 99%);
  background-color: gray;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 8px;
}

.stock-autocomplete-popup::-webkit-scrollbar {
  width: 12px;
}

.stock-autocomplete-popup::-webkit-scrollbar-track {
  background-color: #e4e4e4;
}
.inheritOverflow{
  overflow: inherit !important;
}

