.form {
  &__row {
    display: flex;
    align-items: flex-end;
    gap: 4rem;

    @include mobile {
      flex-direction: column;
      align-items: normal;
    }

    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    &.image {
      img {
        width: 20rem;
      }
    }
  }

  &__fieldset {
    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    .fieldset_switch {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    .heading {
      margin-right: 1rem;
    }
  }

  &__input {
    flex-basis: 50% !important;

    @include mobile {
      flex-basis: 100% !important;
    }

    &--btn {
      display: flex;

      div {
        flex: 1;
        flex-wrap: wrap;
      }
    }

    &.search {
      position: relative;

      .error {
        position: absolute;
        bottom: -2rem;
      }
    }
  }

  &__checkbox {
    span {
      font-size: 1.3rem;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  p {
    margin-bottom: 2rem;
  }

  .item-delete-content {
    font-size: 1.5rem;
  }
  .footer-delete-btns {
    justify-content: flex-end;
    gap: 1rem;
  }

  .product-delete-sku-highlight {
    color: #ff4d4f !important;
  }

  .search-autocomplete-popup {
    height: 200px;
    overflow: auto;
    font-size: 13px;
    cursor: pointer;

    ul {
      list-style-type: none;
    }

    .products-search-list-item {
      margin-bottom: 1.5rem;
    }
  }

  .search-autocomplete-popup::-webkit-scrollbar-thumb {
    //background-image: linear-gradient(180deg, #D0368A 0%, #708AD4 99%);
    background-color: gray;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 8px;
  }

  .search-autocomplete-popup::-webkit-scrollbar {
    width: 12px;
  }

  .search-autocomplete-popup::-webkit-scrollbar-track {
    background-color: #e4e4e4;
  }

  button {
    @include mobile {
      align-items: center;
    }
  }
}
