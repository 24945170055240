$main-bg: #f1f2f4;
$color-white: #fff;
$color-black: #000;
$color-blue: #0d62ff;
$color-delete-btn: #ff4d4f;
$color-grey: #737f8f;

$font-size-small: 1.3rem;
$font-size-normal: 1.6rem;
$font-size-big: 3.2rem;
