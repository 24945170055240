.orders {
  .page__table {
    padding-top: 0;

    td {
      max-width: 20rem;
      padding-right: 10px;
    }
  }

  .page__date_picker {
    display: flex;
    gap: 4rem;
    align-items: center;

    @include mobile {
      align-items: initial;
      flex-direction: column;

      .filter_btn {
        display: none;
      }
    }

    .date_range_picker {
      opacity: 0;
      transition: all 0.3s;

      @include mobile {
        opacity: 1;
      }
    }

    .icon {
      transition: all 0.3s;
    }

    &.show {
      .date_range_picker {
        opacity: 1;
      }

      button .icon {
        transform: rotate(45deg);
      }
    }
  }
}
