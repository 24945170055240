.page.cc_history {
  background-color: none !important;

  .page__body {
    background-color: transparent;
  }
}

.timeline {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: $color-grey;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__credit_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    &::before {
      content: "";
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background-color: $color-blue;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__deposit_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    &::before {
      content: "";
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background-color: #ff4d4f;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__block {
    background-color: $color-white;
    padding: 2rem;
    width: 45%;

    .highlight {
      color: $color-blue;
    }

    &--left {
      align-self: flex-start;
    }

    &--right {
      align-self: flex-end;
    }
  }
}
